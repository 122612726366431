// @flow
import React from 'react';
import moment from 'moment';
import { Link } from 'gatsby';
import type { Edges } from '../../types';

type Props = {
  edges: Edges
};


const Feed = ({ edges }: Props) => (
  <div className="container mx-auto text-center px-2 py-6 shadow-2xl" name="whatwedo">
    <div className="text-3xl xl:text-5xl text-center font-display text-twilamblue">
      What we do
      </div>

    <div className="justify-center flex flex-wrap">
      {edges.map((edge, index) => (
//        <Link to={edge.node.fields.slug} key={index} className="sm:w-64 md:w-48 mx-3">
        <div className="sm:w-64 md:w-48 mx-3">
          <div className="px-0 mb-6">
            <h3 className="mt-0 mb-0 font-body text-twilamblue text-center">{edge.node.frontmatter.title}</h3>
            <img alt={edge.node.frontmatter} src={"/media/" + edge.node.frontmatter.image} className="inline w-32" />
            <div className="text-sm mt-0 font-body text-left">{edge.node.frontmatter.description}</div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Feed;
