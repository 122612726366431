// @flow
import React from 'react';
import moment from 'moment';
import { Link } from 'gatsby';

const Brand = () => (
  <div className="container bg-twilamblue mx-auto py-4 text-white font-display">
    <div className="mx-auto text-center py-6 justify-center lg:flex flex-wrap">
      <div className="w-full justify-center">
        <img src="/media/busy_ceo_cto_1.svg" className="w-16 lg:w-32 mx-auto" />
      </div>
      <div className="w-full text-4xl xl:text-6xl">For busy CEOs and CTOs</div>
      <div className="flex flex-wrap w-full justify-between">
        <div className="text-xl xl:text-2xl mx-auto">Machine Learning</div>
        <div className="text-xl xl:text-2xl mx-auto">Product Management</div>
        <div className="text-xl xl:text-2xl mx-auto">Financial Models</div>
      </div>
    </div>
  </div>
);

export default Brand;
