// @flow
import React from 'react';
import moment from 'moment';
import { Link } from 'gatsby';

const About = () => (
  <div className="container bg-twilamblue mx-auto py-4 text-white" name="whoweare">
    <div className="mx-auto py-6 justify-center flex flex-wrap">
      <div className="text-3xl xl:text-5xl text-center font-display">
        Who we are
      </div>
      <div className="font-body xl:text-xl mx-6">
        I'm Rahim Nathwani. I was born and grew up in London, where I co-founded two consumer-lending related companies. Since 2010 I've lived in Beijing, where I spent 5 years as a Product Manager at large tech companies (Amazon and Google).
      </div>
    </div>
  </div>
);

export default About;
